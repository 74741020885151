<template>
	<div>
		<app-layout>
			<template v-slot:header>
				<Header :title="$t('leave_absences') + ' - ' + $t('detail')" :isFilter="false" />
			</template>
			<template v-slot:header-mobile>
				<HeaderMobile :title="$t('leave_absences') + ' - ' + $t('detail')" :isFilter="false" />
			</template>
			<b-row>
				<b-col cols="12" md="6" class="mb-4 mb-md-0 d-flex">
					<b-card :header="$t('university_informations')" class="soft mb-5">
						<b-row>
							<b-col cols="12" sm="6" md="12" xl="6">
								<b-form-group :label="$t('student_number')">
									<span v-if="leaveAbsenceData.student_number">{{ leaveAbsenceData.student_number
										}}</span>
									<span v-else>-</span>
								</b-form-group>
							</b-col>

							<b-col cols="12" sm="6" md="12" xl="6">
								<b-form-group :label="$t('faculty')">
									<span
										v-if="leaveAbsenceData.student_form && leaveAbsenceData.student_form.faculty_name && leaveAbsenceData.student_form.faculty_name_en">
										{{ $i18n.locale == 'tr' ? leaveAbsenceData.student_form.faculty_name :
											leaveAbsenceData.student_form.faculty_name_en }}
									</span>
									<span v-else>-</span>
								</b-form-group>
							</b-col>

							<b-col cols="12" sm="6" md="12" xl="6">
								<b-form-group :label="$t('program')">
									<span v-if="leaveAbsenceData.program_name && leaveAbsenceData.program_name_en">
										{{ $i18n.locale == 'tr' ? leaveAbsenceData.program_name :
											leaveAbsenceData.program_name_en }}
									</span>
									<span v-else>-</span>
								</b-form-group>
							</b-col>

							<b-col cols="12" sm="6" md="12" xl="6">
								<b-form-group :label="$t('class')">
									<span
										v-if="leaveAbsenceData.student_form && leaveAbsenceData.student_form.class && leaveAbsenceData.student_form.class_en">
										{{ $i18n.locale == 'tr' ? leaveAbsenceData.student_form.class :
											leaveAbsenceData.student_form.class_en }}
									</span>
									<span v-else>-</span>
								</b-form-group>
							</b-col>
						</b-row>
					</b-card>
				</b-col>
				<b-col cols="12" md="6">
					<b-card :header="$t('personal_information')" class="soft mb-5">
						<b-row>
							<b-col cols="12" sm="6" md="12" xl="6">
								<b-form-group :label="$t('name_surname')">
									<span
										v-if="leaveAbsenceData.student_form && leaveAbsenceData.student_form.full_name">
										{{ leaveAbsenceData.name + ' ' + leaveAbsenceData.surname }}
									</span>
									<span v-else>-</span>
								</b-form-group>
							</b-col>

							<b-col cols="12" sm="6" md="12" xl="6">
								<b-form-group :label="$t('nationality')">
									<span
										v-if="leaveAbsenceData.student_form && leaveAbsenceData.student_form.nationality">
										{{ leaveAbsenceData.student_form.nationality }}
									</span>
									<span v-else>-</span>
								</b-form-group>
							</b-col>

							<b-col cols="12" sm="6" md="12" xl="6">
								<b-form-group :label="$t('national_id_passport_no')">
									<span
										v-if="leaveAbsenceData.student_form && leaveAbsenceData.student_form.national_id">
										{{ leaveAbsenceData.student_form.national_id }}
									</span>
									<span v-else>-</span>
								</b-form-group>
							</b-col>

							<b-col cols="12" sm="6" md="12" xl="6">
								<b-form-group :label="$t('mobile_number')">
									<span
										v-if="leaveAbsenceData.student_form && leaveAbsenceData.student_form.mobile_tel">
										{{ leaveAbsenceData.student_form.mobile_tel }}
									</span>
									<span v-else>-</span>
								</b-form-group>
							</b-col>

							<b-col cols="12" sm="6" md="12" xl="6">
								<b-form-group :label="$t('email_address')">
									<span class="text-break"
										v-if="leaveAbsenceData.student_form && leaveAbsenceData.student_form.email">
										{{ leaveAbsenceData.student_form.email }}
									</span>
									<span v-else>-</span>
								</b-form-group>
							</b-col>

							<b-col cols="12" sm="6" md="12" xl="6">
								<b-form-group :label="$t('address')">
									<span v-if="leaveAbsenceData.student_form && leaveAbsenceData.student_form.address">
										{{ leaveAbsenceData.student_form.address }}
									</span>
									<span v-else>-</span>
								</b-form-group>
							</b-col>
						</b-row>
					</b-card>
				</b-col>
			</b-row>
			<b-row>
				<b-col cols="12">
					<b-card :header="$t('application_information')" class="soft mb-5">
						<b-row>
							<b-col sm="12" md="6">
								<b-row>
									<b-col cols="12" sm="6" md="6" lg="4" xl="6">
										<b-form-group :label="$t('application_period')">
											<span v-if="leaveAbsenceData">
												{{ leaveAbsenceData.academic_year }} /
												{{ leaveAbsenceData.fall == 1 ? $t('autumn') : '' }}
												{{ leaveAbsenceData.fall == 1 && leaveAbsenceData.spring == 1 ?
													$t('and') : '' }}
												{{ leaveAbsenceData.spring == 1 ? $t('spring') : '' }}
											</span>
											<span v-else>-</span>
										</b-form-group>
									</b-col>

									<b-col cols="12" sm="6" md="6" lg="4" xl="6">
										<b-form-group :label="$t('leave_of_absence_reason')">
											<span>{{ getLocaleText(leaveAbsenceData, 'reason_name') }}</span>
										</b-form-group>
									</b-col>

									<b-col cols="12" sm="6" md="6" lg="4" xl="6">
										<b-form-group :label="$t('have_you_leave_of_absence_before')">
											<span
												v-if="leaveAbsenceData.student_form && leaveAbsenceData.student_form.before_leave_of_absence">
												{{ leaveAbsenceData.student_form.before_leave_of_absence }}
											</span>
											<span v-else>-</span>
										</b-form-group>
									</b-col>
								</b-row>
							</b-col>

							<b-col sm="12" md="6">
								<b-row>
									<b-col cols="12" sm="6" md="6" lg="4" xl="6">
										<b-form-group :label="$t('number_of_leave_of_absence')">
											<span>{{ getObjectValue(leaveAbsenceData.student_form,
												'leave_of_absence_count') }}</span>
										</b-form-group>
									</b-col>

									<b-col cols="12" sm="6" md="6" lg="4" xl="6"
										v-if="leaveAbsenceData.file && leaveAbsenceData.file.file_name">
										<b-form-group :label="$t('excuse_letter')">
											<span class="mr-3 mb-1 d-inline-block">{{ leaveAbsenceData.file.file_name
												}}</span>
											<div class="d-inline-block">
												<a href="javascript:;" class="mr-4"
													@click="leaveAbsenceFile('show', leaveAbsenceData.file)">
													<i class="ri-eye-line mr-1 top-plus-2"></i> {{ $t('show') }}
												</a>
												<a href="javascript:;"
													@click="leaveAbsenceFile('download', leaveAbsenceData.file)">
													<i class="ri-download-2-line mr-1 top-plus-1"></i> {{ $t('download')
													}}
												</a>
											</div>
										</b-form-group>
									</b-col>

									<b-col cols="12" sm="6" md="6" lg="4" xl="6">
										<b-form-group :label="$t('explanation')">
											<span v-if="leaveAbsenceData.explanation">{{ leaveAbsenceData.explanation
												}}</span>
											<span v-else>-</span>
										</b-form-group>
									</b-col>
								</b-row>
							</b-col>
						</b-row>
						<b-row>
							<b-col sm="12" md="6">
								<ValidationObserver ref="messageForm">
									<ValidationProvider name="explanation" rules="required" v-slot="{ valid, errors }">
										<b-form-group :label="$t('add_note')">
											<b-form-textarea type="text" v-model="form.explanation" />
											<span class="invalid-feedback-custom" v-show="errors[0]"
												v-html="errors[0]" />
										</b-form-group>
									</ValidationProvider>
								</ValidationObserver>

								<b-button variant="primary" class="btn-sm float-right" @click="updateMessageForm"
									:disabled="formLoading">
									{{ $t('save') }}
								</b-button>

								<b-button v-if="Object.assign(leaveAbsenceMessageData).length > 1"
									variant="outline-secondary" class="btn-sm float-right mr-2"
									@click="isTableVisible = !isTableVisible">
									<i class="ri-filter-3-line top-plus-1 mr-1"></i>
									{{ $t('other_notes') }}
								</b-button>
							</b-col>
						</b-row>
						<b-row>
							<b-col v-if="isTableVisible && Object.assign(leaveAbsenceMessageData).length > 1" cols="12"
								md="6">
								<b-table :empty-text="$t('there_are_no_records')" bordered striped responsive
									:items="leaveAbsenceMessageData" :fields="fields" show-empty sort-by="id"
									class="my-3 table-dropdown no-scrollbar border rounded">
								</b-table>
							</b-col>
						</b-row>
					</b-card>
				</b-col>
			</b-row>
			<div>
				<div class="mb-3 d-inline-block"></div>
				<div v-if="leaveAbsenceData && !leaveAbsenceData.read_only && step.id">
					<h6 class="text-uppercase mb-4">{{ $t('approval_procedures').toUpper() }}</h6>
					<ValidationObserver ref="updateForm">
						<div class="row">
							<div class="col-12 col-md-6 col-lg-6 col-xl-6">
								<ValidationProvider name="status" rules="required" v-slot="{ valid, errors }">
									<b-form-group :label="$t('status')">
										<template>
											<div>
												<multiselect placeholder="Yok" v-model="updateForm.status"
												             :options="computeStatusOptions"
												             :custom-label="statusOptionsCustomLabel" :select-label="''"
												             :selected-label="''" :deselect-label="''" :searchable="false">
												</multiselect>
												<div class="invalid-feedback d-block" v-if="errors[0]"
												     v-html="errors[0]"></div>
											</div>
										</template>
									</b-form-group>
								</ValidationProvider>
							</div>
							<div class="col-12 col-md-6 col-lg-6 col-xl-9">
								<ValidationProvider name="explanation" rules="required" v-slot="{ valid, errors }">
									<b-form-group :label="$t('explanation')">
										<b-form-textarea no-resize rows="4" class="h-40 h-auto-sm" maxlength="100"
										                 v-model="updateForm.reason"></b-form-textarea>
										<div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
									</b-form-group>
								</ValidationProvider>
							</div>
						</div>
						<b-form-group>
							<b-button variant="primary" @click="leaveAbsenceUpdate">
                                {{ $t('update').toUpper() }}
							</b-button>
						</b-form-group>
					</ValidationObserver>
				</div>
				<div v-if="Object.assign(steps).length">
					<b-table :empty-text="$t('there_are_no_records')" bordered striped responsive :items="steps"
					         :fields="[
                            { key: 'rank', label: this.toUpperCase('rank'), thClass: 'text-center', tdClass: 'text-center' },
                            { key: 'approvers', label: this.toUpperCase('can_approvers'), thClass: 'text-center' },
                            { key: 'approved', label: this.toUpperCase('approved_by'), thClass: 'text-center' },
                            { key: 'status', label: this.toUpperCase('status'), thClass: 'text-center' },
                            { key: 'reason', label: this.toUpperCase('explanation'), thClass: 'text-center' },
                            { key: 'updated_at', label: this.toUpperCase('updated_at'), thClass: 'text-center' },
                            { key: 'buttons', label: '', tdClass:'width-200'}
                        ]" show-empty sort-by="rank" class="mb-3 table-dropdown no-scrollbar border rounded">
						<template #cell(approvers)="data">
                            <span v-for="approver in data.item.approvers">
                                {{ getLocaleText(approver, 'explanation') }} <br>
                            </span>
						</template>
						<template #cell(approved)="data">
                            <span v-for="approver in data.item.approved">
                                {{ approver.name }} {{ approver.surname }}<br>
                            </span>
						</template>
						<template #cell(status)="data">
							{{ $i18n.locale == 'tr' ? data.item.status_name : data.item.status_name_en }}
						</template>
                        <template #cell(buttons)="row">
                            <div class="d-flex">
                                <b-button variant="outline-success"
                                          size="xs"
                                          v-if="row.item.allow_set_active==true && checkPermission('approvalstep_setactive')"
                                          @click="setActive(row.item.id)">
                                    {{ $t('set_as_active_step').toUpper() }}
                                </b-button>
                            </div>
                        </template>
					</b-table>
				</div>
			</div>
		</app-layout>
	</div>
</template>
<script>
	// Template
	import AppLayout from "@/layouts/AppLayout";
	import Header from "@/layouts/AppLayout/Header";
	import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

	// Services
	import LeaveOfAbsenceService from "@/services/LeaveOfAbsenceService";
	import ApprovalStepService from "@/services/ApprovalStepService";

	// Components
	import CommonModal from "@/components/elements/CommonModal"

// Other
	import {ValidationProvider, ValidationObserver} from "vee-validate"

	export default {
		components: {
			AppLayout,
			Header,
			HeaderMobile,
			CommonModal,
			ValidationProvider,
			ValidationObserver,
		},
		data() {
			return {
				id: this.$route.params.id,
				leaveAbsenceData: {},
				leaveAbsenceMessageData: {},
				step: {},
				steps: {},
				messages: {},
				statuses: {},
				updateForm: {
					status: null,
					reason: null,
				},
				form: {
					explanation: ''
				},
	            formLoading: false,
				fields: [
					{
						key: 'created_by',
						label: this.toUpperCase('created_by'),
						thClass: 'text-left',
					},
					{
						key: 'message',
						label: this.toUpperCase('message'),
						thClass: 'text-left'
					},
					{
						key: 'created_at',
						label: this.toUpperCase('created_at'),
						thClass: 'text-left',
						formatter: this.formatDate,
					},
				],
				isTableVisible: false,
			}
		},

		metaInfo() {
			return {
				title: this.$t('leave_absences') + ' - ' + this.$t('detail')
			}
		},
		created() {
			this.getLeaveAbsence();
			this.getLeaveAbsenceMessages();

		},
		computed: {
			computeStatusOptions() {
				let options = [];
				if (this.statuses) {
					for (const item in this.statuses) {
						options.push(item);
					}
				}
				return options;
			},
		},
		methods: {
			getLeaveAbsence() {
				LeaveOfAbsenceService.get(this.id)
				.then(response => {
						this.leaveAbsenceData = response.data.data;
						this.step = this.leaveAbsenceData.step == null ? {} : this.leaveAbsenceData.step;
					if (this.leaveAbsenceData.step != null) {
						this.statuses = this.leaveAbsenceData.step.statuses;
					}
					this.steps = this.leaveAbsenceData.steps;
				})
				.catch(e => {
					//this.$router.push('/404')
					this.$toast.error(e.data.message)
				})

			},
			leaveAbsenceFile(method, file) {
                if(this.checkPermission('leaveofabsence_download')){
                    LeaveOfAbsenceService.downloadFile(method, file.uuid)
                                         .then(response => {
                                             if(method == 'show'){
                                                 this._openBinaryPdfInNewTab(response)
                                             } else {
                                                 this._downloadFile(response, file.file_name)
                                             }
                                         })
                                         .catch(error => {
                                             if (error.status == 422) {
                                                 this.$toast.error(error.data.errors.file[0]);
                                             }
                                             if (error.status == 406) {
                                                 this.$toast.error(this.$t('api.' + error.data.message));
                                             }
                                         });
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
			},
			async leaveAbsenceUpdate() {
                if(this.checkPermission('leaveofabsence_update')){
                    const isValid = await this.$refs.updateForm.validate();
                    if (isValid) {
                        this.$swal.fire({
                            title: this.$t('warning'),
                            text: this.$t('leave_of_absence_statement'),
                            showCancelButton: true,
                            confirmButtonText: this.$t('yes'),
                            cancelButtonText: this.$t('no'),
                        }).then((result) => {
                            if (result.isConfirmed) {
                                LeaveOfAbsenceService.update(this.step.id, this.updateForm)
                                                     .then(response => {
                                                         this.$toast.success(this.$t('api.' + response.data.message));
                                                         this.getLeaveAbsence()
                                                     })
                                                     .catch(error => {
                                                         if (error.status == 422) {
                                                             if (error.data.errors.status) {
                                                                 this.$refs.updateForm.errors.status.push(error.data.errors.status[0]);
                                                             }
                                                             if (error.data.errors.reason) {
                                                                 this.$refs.updateForm.errors.explanation.push(error.data.errors.reason[0]);
                                                             }
                                                         }
                                                         if (error.status == 406) {
                                                             this.$toast.error(this.$t('api.' + error.data.message));
                                                         }
                                                     });
                            }
                        })
                    }
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
			},
			statusOptionsCustomLabel(key) {
				let label = key;
				if (this.statuses[key]) {
					label = this.$i18n.locale == 'tr' ? this.statuses[key].name : this.statuses[key].name_en;
				}
				return label;
			},
            setActive(id){
                this.$swal.fire({
                    text: this.$t('are_you_sure_to_set_active'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                }).then((result) => {
                    if (result.isConfirmed) {
                        ApprovalStepService.setActive(id).then(response=>{
                            this.showMessage(response);
                            this.getLeaveAbsence();
                        }).catch(e=>{
                            this.showErrors(e);
                        });
                    }
                });
			},
			async updateMessageForm() {
				const isValid = await this.$refs.messageForm.validate();
				if (isValid){
					const formData = new FormData();
					formData.append("message", this.form.explanation);
					formData.append("leave_of_absence_id", this.id);

					if (isValid) {
						this.formLoading = true;
						LeaveOfAbsenceService.storeMessage(formData)
							.then(response => {
								this.$toast.success(this.$t('api.' + response.data.message));
								this.getLeaveAbsenceMessages()
							})
							.catch(e => {
								this.showErrors(e, this.$refs.formModalValidate);
							})
							.finally(() => {
								this.formLoading = false
							})

					}

				}

			},
			getLeaveAbsenceMessages() {
				LeaveOfAbsenceService.getMessages(this.id)
					.then(response => {
						this.leaveAbsenceMessageData = response.data.data;
						if (this.leaveAbsenceMessageData && this.leaveAbsenceMessageData.length > 0) {
							const sortedMessages = this.leaveAbsenceMessageData.sort((a, b) => {
								return b.created_at.localeCompare(a.created_at);
							});

							const latestMessage = sortedMessages[0];

							if (latestMessage && latestMessage.message) {
								this.form.explanation = latestMessage.message;
							}
						}
					})
					.catch(e => {
						this.$toast.error(e.data.message)
					});
			}
		},
	};
</script>
